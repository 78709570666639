import React, { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

// IMPORTANT: Update this with your Cloudflare Worker URL
const WORKER_URL = "https://shy-block-d95e.grantwang31.workers.dev"

export default function SecurePageProtection(props) {
    const { pageName, children, overlayStyle } = props
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    // Authentication endpoints
    const authEndpoint = `${WORKER_URL}/authenticate`
    const validateEndpoint = `${WORKER_URL}/validate`

    // Check existing token on component mount
    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem(`auth_token_${pageName}`)

            if (token) {
                try {
                    const response = await fetch(validateEndpoint, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ token }),
                    })

                    const data = await response.json()
                    if (data.valid) {
                        setIsAuthenticated(true)
                    }
                } catch (err) {
                    console.error("Token validation error:", err)
                }
            }

            setIsLoading(false)
        }

        checkAuth()
    }, [pageName, validateEndpoint])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")

        try {
            const response = await fetch(authEndpoint, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ page: pageName, password }),
            })

            const data = await response.json()

            if (data.success) {
                // Store token in session storage
                sessionStorage.setItem(`auth_token_${pageName}`, data.token)
                setIsAuthenticated(true)
            } else {
                setError(data.message || "Invalid password")
            }
        } catch (err) {
            setError("Authentication failed. Please try again.")
        }
    }

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
            >
                <div className="loader">Loading...</div>
            </div>
        )
    }

    // Always render children, but overlay the auth form if not authenticated
    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {children}

            {!isAuthenticated && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                        ...overlayStyle,
                    }}
                >
                    <div
                        style={{
                            maxWidth: "400px",
                            width: "90%",
                            padding: "30px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.2)",
                            backgroundColor: "white",
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                                style={{
                                    width: "100%",
                                    padding: "12px",
                                    marginBottom: "15px",
                                    borderRadius: "4px",
                                    border: "1px solid #ddd",
                                    fontSize: "16px",
                                }}
                                autoFocus
                            />
                            {error && (
                                <p
                                    style={{
                                        color: "red",
                                        marginBottom: "15px",
                                        textAlign: "center",
                                    }}
                                >
                                    {error}
                                </p>
                            )}
                            <button
                                type="submit"
                                style={{
                                    width: "100%",
                                    padding: "12px",
                                    backgroundColor: "#000",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

// Add property controls for use in Framer
SecurePageProtection.propertyControls = {
    pageName: {
        type: ControlType.String,
        title: "Page Name",
        defaultValue: "project1",
    },
    overlayStyle: {
        type: ControlType.Object,
        title: "Overlay Style",
        controls: {
            backgroundColor: {
                type: ControlType.Color,
                title: "Backdrop Color",
                defaultValue: "rgba(0, 0, 0, 0.7)",
            },
        },
    },
}
